import { IconChevronRight } from "components/icons";
import Style from "./PodSlider.module.scss";
import clsx from "clsx";


function RightButton({
    onClick = () => null, 
    isDisabled = false,
    className 
}){

    return(
        <button 
            type="button" 
            className={clsx(Style.navigateBtn, Style.right, className)}
            onClick={onClick}
            disabled={isDisabled}
        >
            <IconChevronRight width="30" height="30" />
        </button>
    )
}


export default RightButton;