"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import "keen-slider/keen-slider.min.css";
import Style from "./PodSlider.module.scss";
import utils from "styles/globals/utils.module.scss";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useKeenSlider } from "keen-slider/react";
import RenderHeading from "components/blocks/RenderHeading";
import LeftButton from "./LeftButton";
import RightButton from "./RightButton";
import { useEffect, useState } from "react";
import Dots from "./Dots";



function PodSlider({ 
	size = 4,
	className,
	backgroundColor = "",
	showTitle = false,
	title,
	headingType,
	element,
	position,
	useButtons = null,
	children,
	settings = {},
	leftBtnClassName = "",
	rightBtnClassName = "",
	lastChild,
	showDots,
}) {

	const [ isLoaded, setIsLoaded ] = useState(false);
	const [ active, setActive ] = useState(0);
	const [ showButtons, setShowButtons ] = useState(false);
	const [ options, setOptions ] = useState({})

	const [ sliderRef, instanceRef ] = useKeenSlider(options,[
			(slider) => {
				slider.on('created', () => {
					setIsLoaded(true)
				})
			}
		]
	);


	
	useEffect(() => {
		if( children ){
			setShowButtons(useButtons ?? children.length > size);

			setOptions({
				...settings, 
				initial: 0,
				slides: {
					perView: size,
					spacing: 10,
				},
				slideChanged(slider) {
					setActive(slider.track.details.rel);
				}		
			})
		}

	}, [ children ]);



	useEffect(() => {
		instanceRef.current.update({
			...options
		})

	}, [ instanceRef, options ])
	

	  

  return (
		<article
			className={clsx(
				Style.block,
				className,
				backgroundColor && utils[`bg_${backgroundColor}`]
			)}
		>
			{showTitle && (
				<RenderHeading
					{...{ headingType, element, position }}
					className={clsx(Style.sliderTitle, utils.fs_2)}
				>
					{title}
				</RenderHeading>
			)}
			
			{showButtons && (
				<LeftButton
					className={leftBtnClassName}
					onClick={(e) => e.stopPropagation() || instanceRef?.current?.prev()}
					isDisabled={active === 0}
				/>
			)}

			<div className={clsx(Style.container, !isLoaded && Style.hidden)}>
				<div ref={sliderRef} className={clsx(Style.body, "keen-slider")}>
					{children}
				</div>
			</div>

			{( showDots && instanceRef.current ) && (
				<Dots 
					instance={instanceRef?.current} 
					currentSlide={active}
				/>
			)}

			{showButtons && (
				<RightButton
					className={rightBtnClassName}
					onClick={(e) => e.stopPropagation() || instanceRef?.current?.next()}
					isDisabled={active === instanceRef?.current?.track?.details?.slides.length - size}
				/>
			)}
		</article>
	);
}

PodSlider.propTypes = {
  title: PropTypes.string,
  mobileSlidesToScroll: PropTypes.number,
  settings: PropTypes.object
};

export default PodSlider;